exports.components = {
  "component---src-pages-docs-404-js": () => import("./../../../src/pages/docs/404.js" /* webpackChunkName: "component---src-pages-docs-404-js" */),
  "component---src-pages-docs-api-chat-rest-tsx": () => import("./../../../src/pages/docs/api/chat-rest.tsx" /* webpackChunkName: "component---src-pages-docs-api-chat-rest-tsx" */),
  "component---src-pages-docs-api-control-api-tsx": () => import("./../../../src/pages/docs/api/control-api.tsx" /* webpackChunkName: "component---src-pages-docs-api-control-api-tsx" */),
  "component---src-pages-docs-examples-tsx": () => import("./../../../src/pages/docs/examples.tsx" /* webpackChunkName: "component---src-pages-docs-examples-tsx" */),
  "component---src-pages-docs-how-to-pub-sub-tsx": () => import("./../../../src/pages/docs/how-to/pub-sub.tsx" /* webpackChunkName: "component---src-pages-docs-how-to-pub-sub-tsx" */),
  "component---src-pages-docs-index-tsx": () => import("./../../../src/pages/docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-docs-sdks-index-tsx": () => import("./../../../src/pages/docs/sdks/index.tsx" /* webpackChunkName: "component---src-pages-docs-sdks-index-tsx" */),
  "component---src-templates-api-reference-tsx": () => import("./../../../src/templates/apiReference.tsx" /* webpackChunkName: "component---src-templates-api-reference-tsx" */),
  "component---src-templates-document-tsx": () => import("./../../../src/templates/document.tsx" /* webpackChunkName: "component---src-templates-document-tsx" */)
}

